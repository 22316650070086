import React, { useEffect, useState, useRef } from 'react';
import Loading from '../../loading';
import { getWilayatBuildingtop8List } from '../FilterProperty/SetServices';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {RootState} from '../../reduxstate/store'
import { Link } from 'react-router-dom';

const PopularArea = ({ setLoading }) => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const webURL=process.env.REACT_APP_WEBSITE_URL;
  const [top8WilayatList, setTop8WilayatList] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const wilayatBuildingtop8List = useSelector((state: RootState) => state.areas.wilayatBuildingtop8List);
  const queryParams = new URLSearchParams(location.search);
  const goToPostPopularAreabyWilayat = (wilayatID, wilayatName, id) => {
    const pathname = id === 1 ? 'rent' : 'sale';
    const searchParams = [];
    // if(wilayatID===12 || wilayatID===1){
    // searchParams.push('building');
    // }else{
    //   searchParams.push('property');
    // }
  
    if (wilayatID) {
      const trimmedStr = wilayatName.trim();
      const addresss = trimmedStr.replace(/\s/g, '-');
      searchParams.push(`${wilayatID===12 ||wilayatID===1 ? "building":"property"}-in-${addresss}`.toLowerCase());
      queryParams.set("type", "wilayat")
        queryParams.set("id", wilayatID.toString())
    } else {
      searchParams.push('property-oman');
    }
    const search = searchParams.join('/');
    return `${webURL}${pathname}/${search}?${queryParams.toString()}`;
  };

  const fetchWilayatList = async () => {
    try {
      setIsFetching(true);
      const data = await getWilayatBuildingtop8List();
      setTop8WilayatList(data);
      setIsFetching(false);
    } catch (error) {
      console.error('Error fetching top 8 wilayat list:', error);
      setIsFetching(false);
      setLoading(false);
    }
  };
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isFetching && !top8WilayatList) {
          fetchWilayatList();
          observer.unobserve(entry.target);
        }
      });
    };
  // useEffect(() => {
  //   console.log('Setting OtherListTab');
  //   const observerOptions = {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 0.1,
  //   };

  //   const observerCallback = (entries, observer) => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting && !isFetching && !top8WilayatList) {
  //         fetchWilayatList();
  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(observerCallback, observerOptions);

  //   if (sectionRef.current) {
  //     observer.observe(sectionRef.current);
  //   }

  //   return () => {
  //     if (sectionRef.current) {
  //       observer.unobserve(sectionRef.current);
  //     }
  //   };
  // }, [isFetching, top8WilayatList]);
  useEffect(() => {
    if (isFetching || top8WilayatList) return; // Prevent multiple triggers
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (sectionRef.current) observer.observe(sectionRef.current);
  
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current); // Properly clean up observer
      }
      observer.disconnect(); // Disconnect when component unmounts
    };
  }, [isFetching, top8WilayatList]);
  

  const renderWilayatItems = (items, columnWidth) => {
    return items.map((item, index) => (
      <a
        key={index}
        className={`col-md-${columnWidth} pb-4`}
        href={goToPostPopularAreabyWilayat(item.wilayatID, item.name, 1)}
        title={`Properties listed in ${item.name}, Oman`}
      >
        <div className='relative_box imggradient propertycard'>
          <img
            width="300"
            height="200"
            srcSet={item.imageURL}
            src={item.imageURL}
            alt={t('exploreProperties')}
            loading="lazy"
            className='img-fluid handlepopularArea'

          />
          <h3 className='ps-3 imgtext popularareatxt1'>
            <span className='popularareatxt w-600'>{item.name}</span>
          </h3>
        </div>
      </a>
    ));
  };

  return (
    <div
      className='container process-section dc4e95e1 font-family-poppins'
      ref={sectionRef}
    >
      <div className='text-center'>
        <h2 className='font-family-poppins _0ff3d3ee pb-4'>
          {t('popularAreas')}
        </h2>
      </div>
      {/* {isFetching && <Loading />} */}
      <div className='row gx-2' >
        {wilayatBuildingtop8List && wilayatBuildingtop8List.length > 0 && (
          <div className='row gx-2 mb-2' style={{ paddingBottom: '16px' }}>
            {renderWilayatItems(wilayatBuildingtop8List.slice(0, 4), 3)}
          </div>
        )}
        {wilayatBuildingtop8List && wilayatBuildingtop8List.length > 4 && (
          <div className='row gx-2'>
            {renderWilayatItems(wilayatBuildingtop8List.slice(4, 8), 3)}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(PopularArea);
