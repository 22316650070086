import React, { useState, useEffect, lazy, Suspense } from 'react';
import './browses.scss'
import {useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FaBuilding = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaBuilding })));
const FaHome = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaHome })));
const BsBuildingsFill = lazy(() => import('react-icons/bs').then(module => ({ default: module.BsBuildingsFill })));



const BrowseProperty = ({ Bedlist,goToPostsbedroom,goToPostResidential }) => {
    const [activeTab, setActiveTab] = useState(1);
    const { t } = useTranslation();
    const [OtherList, setOtherListTab] = useState([]);
    useEffect(() => {
        setOtherListTab(t('OtherList', { returnObjects: true }));
    }, [t]);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className='container dc4e95e1'>
            <div aria-label='Property Section'>
                <h2 className=' font-family-poppins _0ff3d3ee py-4 text-center'>
                    {t('BrowseProperty')}
                    </h2>
                <div className="row gapmbs">
                    <div className=' col-md-4 pb-4'>
                        <div className='_532534f0'>
                            <div className="e52f8410">
                            <Suspense fallback={<div>Loading...</div>}>
                                    <FaHome className='a978a310 ff-icon' />
                                </Suspense>
                                <div className="b78bf485">{t('Residential')}</div>
                            </div>

                            <div className="da262e87">
                                <div className='_42bfff83'>
                                    <span className={`_3d4fe89d ${activeTab === 1 && '_92cbfb27'}`} onClick={() => handleTabClick(1)}>{t('Flat')}</span>
                                    <span className={`_3d4fe89d ${activeTab === 2 && '_92cbfb27'}`} onClick={() => handleTabClick(2)}>{t('Villa')}</span>
                                    <span className={`_3d4fe89d ${activeTab === 3 && '_92cbfb27'}`} onClick={() => handleTabClick(3)}>{t('Others')}</span>

                                </div>
                                <div className="_8be1dcd8">
                                    {/* <button className='_79979b2d _36f014de _79979b2d'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className="_9496c00e _2bb78f0a" viewBox="0 0 32 32"><path d="M7.55 3.36c-.8-.8-.7-2.1.1-2.8.8-.7 2-.7 2.7 0l14 14c.8.8.8 2 0 2.8l-14 14c-.8.8-2 .8-2.8.1-.8-.8-.8-2-.1-2.8l.1-.1 12.6-12.5-12.6-12.7z"></path></svg>
                                </button> */}
                                    <div className='brower-Pcss'>
                                        <div className="react-swipeable-view-container brower-Pcss-1" >
                                            <div className="brower-Pcss-2" >
                                                <div className="bf4108cc">
                                                    {activeTab === 1 && <>
                                                        {Bedlist?.slice(0,4).map((value, index) => (

                                                            <Link to={goToPostsbedroom(value, 1, 8)} key={index} className='fa0a6262 browse'
                                                           
                                                            title={`${value} BHK Residential Flat in Oman`}
                                                             >
                                                                <span className="_72a960ac"> {value}</span>
                                                                <span className="_09808be3">{t('Bedroom')}</span>
                                                            </Link>
                                                        ))}


                                                    </>

                                                    }
                                                    {activeTab === 2 && <>
                                                        {Bedlist.map((value, index) => (

                                                            <Link  key={index} className='fa0a6262 browse' to={goToPostsbedroom(value, 1, 6)} title={`${value} BHK Residential Villa in Oman`}>
                                                                <span className="_72a960ac"> {value}</span>
                                                                <span className="_09808be3">{t('Bedroom')}</span>
                                                            </Link>
                                                        ))}


                                                    </>

                                                    }
                                                    {activeTab === 3 && <>
                                                        {OtherList.map((value, index) => (

                                                            <Link to={goToPostResidential(value, 1)} key={index} className='fa0a6262 browse'
                                                            title={`Residential ${value} in Oman`}
                                                             >
                                                                <span className="_72a960ac"> </span>
                                                                <span className="_09808be3"> {value}</span>
                                                            </Link>
                                                        ))}


                                                    </>

                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' col-md-4 pb-4'>
                        <div className='_532534f0'>
                            <div className="e52f8410">
                            <Suspense fallback={<div>Loading...</div>}>
                                    <BsBuildingsFill className='a978a310 ff-icon' />
                                </Suspense>

                                <div className="b78bf485">{t('Commercial')}</div>
                            </div>
                            <div className="da262e87">
                                <div className='_42bfff83'>
                                    <span className="_3d4fe89d _92cbfb27">{t('Popular')}</span>

                                </div>
                                <div className="_8be1dcd8">

                                    <div className='brower-Pcss'>
                                        <div className="react-swipeable-view-container brower-Pcss-1" >
                                            <div className="brower-Pcss-2" >
                                                <div className="bf4108cc">
                                                <Link to={goToPostResidential('Shop', 2)} title={`Commercial Shop in Oman`} className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Shop')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Business Center', 2)} title={`Commercial Business Center in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Business Center')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Ware House', 2)}  title={`Commercial Ware House in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Ware House')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Shop', 2)} title={`Commercial Factory in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Factory')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Ware House', 2)} title={`Commercial Hall in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Hall')}</span>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='_532534f0'>
                            <div className="e52f8410">
                            <Suspense fallback={<div>Loading...</div>}>
                                    <FaBuilding className='a978a310 ff-icon' />
                                </Suspense>
                                <div className="b78bf485">{t('Building')}</div>
                            </div>
                            <div className="da262e87">
                                <div className='_42bfff83'>
                                    <span className="_3d4fe89d _92cbfb27">{t('Popular')}</span>

                                </div>
                                <div className="_8be1dcd8">

                                    <div className='brower-Pcss' >
                                        <div className="react-swipeable-view-container brower-Pcss-1" >
                                            <div className="brower-Pcss-2">
                                                <div className="bf4108cc">
                                                <Link to={goToPostsbedroom(1,'Flat', 8)}  title={`Flat in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Flat')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Flat', 1)}  title={`Hall in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Hall')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Shop', 2)}  title={`Shop in Oman`}  className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Shop')}</span>
                                                    </Link>
                                                    <Link to={goToPostResidential('Ware House', 2)}  title={`Ware House in Oman`} className='fa0a6262 browse' >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Ware House')}</span>
                                                    </Link>

                                                    <Link className='fa0a6262 browse' to={goToPostResidential('Town House', 1)} >
                                                        <span className="_72a960ac"></span>
                                                        <span className="_09808be3">{t('Town House')}</span>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrowseProperty
