//enum.tsx
import { useTranslation } from 'react-i18next';
export function propertyMasterTypeEnumid(listingPurposeTypeId: number): string {
  var purpose = {
    1: "Residential",
    2: "Commercial",
    3: "Building",
  }
  return purpose[listingPurposeTypeId] || null;
}
export function propertyMasterTypeEnumdesc(listingPurposeTypedesc: string): number {
  var purpose = {
    "Residential": 1,
    "Commercial": 2,
    "Building": 3,
  }
  return purpose[listingPurposeTypedesc] || null;
}

export function getPropertySubTypewithspace(subType: number) {
  var propertysubType = {
    1: 'Building',
    2: 'Town House',
    3: 'Lower Portion',
    4: 'Upper Portion',
    5: 'Pent House',
    6: 'Villa',
    7: 'Room',
    8: 'Flat',
    9: 'Mix Use',
    10: 'Ware House',
    11: 'Shop',
    12: 'Business Center',
    13: 'Factory',
    14: 'Hall',
    15: 'Plot',
    16: 'Project'
  }
  return propertysubType[subType] || null;
}
export function getPropertySubTypeEnumdesc(subType: string): number {
  var propertysubType = {
    'Building': 1,
    'TownHouse': 2,
    'LowerPortion': 3,
    'UpperPortion': 4,
    'PentHouse': 5,
    'Villa': 6,
    'Room': 7,
    'Flat': 8,
    'MixUse': 9,
    'WareHouse': 10,
    'Shop': 11,
    'BusinessCenter': 12,
    'Factory': 13,
    'Hall': 14,
    'Plot': 15,
    'Project': 16
  }
  return propertysubType[subType] || null;
}
export function getPropertySubType(subType: string): string {
  var propertysubType = {
    'Building': 'Building',
    'TownHouse': 'Town House',
    'LowerPortion': 'Lower Portion',
    'UpperPortion': 'Upper Portion',
    'PentHouse': 'Pent House',
    'Villa': 'Villa',
    'Room': 'Room',
    'Flat': 'Flat',
    'MixUse': 'Mix Use',
    'WareHouse': 'Ware House',
    'Shop': 'Shop',
    'BusinessCenter': 'Business Center',
    'Factory': 'Factory',
    'Hall': 'Hall',
    'Plot': 'Plot',
    'Project': 'Project'
  }
  return propertysubType[subType] || null;
}
export function getPropertySubTypeEnumdeschome(subType: string): number {
  var propertysubType = {
    'Building': 1,
    'Town House': 2,
    'Lower Portion': 3,
    'Upper Portion': 4,
    'Pent House': 5,
    'Villa': 6,
    'Room': 7,
    'Flat': 8,
    'Mix Use': 9,
    'Ware House': 10,
    'Shop': 11,
    'Business Center': 12,
    'Factory': 13,
    'Hall': 14,
    'Plot': 15,
    'Project': 16
  }
  return propertysubType[subType] || null;
}
export function getPropertySubTypeEnumid(subType: string): number {
  var propertysubType = {
    'Building': 1,
    'TownHouse': 2,
    'LowerPortion': 3,
    'UpperPortion': 4,
    'PentHouse': 5,
    'Villa': 6,
    'Room': 7,
    'Flat': 8,
    'MixUse': 9,
    'WareHouse': 10,
    'Shop': 11,
    'BusinessCenter': 12,
    'Factory': 13,
    'Hall': 14,
    'Plot': 15,
    'Project': 16
  }
  return propertysubType[subType] || null;
}
export function getListId(listId: string) {
  var ListId = {
    'rent': 1,
    'sale': 2,
  }
  return ListId[listId] || 1;
}
export function getListName(listId: string): string {
  const listMapping: Record<string, string> = {
    'rent': "Rent",
    'sale': "Buy",
  };

  return listMapping[listId] || "Rent"; // Default to "Rent" if the key is not found
}

export function getListdesc(listId: string) {
  var ListId = {
    1: 'Rent',
    2: 'Sale',
  }
  return ListId[listId] || 1;
}
export function getListbyid(listId: string) {
  var ListId = {
    1: 'Rent',
    2: 'Buy'
  }
  return ListId[listId] || "Rent";
}
export function getPropertySubEnumdesc(subType: string): number {
  var propertysubType = {
    'apartment': 1,
    'townhouse': 2,
    'lowerportion': 3,
    'upperportion': 4,
    'penthouse': 5,
    'villa': 6,
    'room': 7,
    'flat': 8,
    'mixuse': 9,
    'warehouse': 10,
    'shop': 11,
    'businesscenter': 12,
    'factory': 13,
    'hall': 14,
    'plot': 15,
    'project': 16,
    'building': 1,
    'commercial': 2,
    "property":1,
    "residental":1
  }
  return propertysubType[subType] || null;
}
export enum PropertyType {
  Apartment = "Building,Residential",
  Townhouse = "TownHouse,Residential",
  Lowerportion = "LowerPortion,Residential",
  Upperportion = "UpperPortion,Residential",
  Penthouse = "PentHouse,Residential",
  Villa = "Villa,Residential",
  Room = "Room,Residential",
  Flat = "Flat,Residential",

  Mixuse = "MixUse,Commercial",
  Warehouse = "WareHouse,Commercial",
  Shop = "Shop,Commercial",
  Businesscenter = "BusinessCenter,Commercial",
  Factory = "Factory,Commercial",
  Hall = "Hall,Commercial",
  Plot = "Plot,null",

  Building = "Building,ResidentialCommercial",
  Commercial = "null,Commercial",
  Property="null,Residential",
  Residental="null,ResidentialCommercial"
}

export function getPropertyTypeDetails(propertySubType: string): string {
  return PropertyType[propertySubType as keyof typeof PropertyType] || null;
}
export function getPropertySubTypeEnum(subType: number) {
  var propertysubType = {
    1: 'Apartment',
    2: 'TownHouse',
    3: 'LowerPortion',
    4: 'UpperPortion',
    5: 'PentHouse',
    6: 'Villa',
    7: 'Room',
    8: 'Flat',
    9: 'MixUse',
    10: 'WareHouse',
    11: 'Shop',
    12: 'BusinessCenter',
    13: 'Factory',
    14: 'Hall',
    15: 'Plot',
    16: 'Project'
  }
  return propertysubType[subType] || null;
}