import React, { useEffect, useRef, useState } from 'react';
import { Trans,useTranslation } from 'react-i18next';
import "./succeess.scss";

const CountUp = ({ end, duration, isPercentage }) => {
  const [count, setCount] = useState(0);
  const start = useRef(0);
  const startTime = useRef(null);

  useEffect(() => {
    startTime.current = null;
    start.current = count;
    const increment = end - start.current;
    const step = (timestamp) => {
      if (!startTime.current) startTime.current = timestamp;
      const elapsed = timestamp - startTime.current;
      const progress = Math.min(elapsed / duration, 1);
      setCount(start.current + increment * progress);
      if (elapsed < duration) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [end, duration]);

  return (
    <span>
      {isPercentage ? `${Math.round(count)}%` : `${count.toFixed(0).padStart(2, '0')}`}
    </span>
  );
};

const SuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div className='py-5'>
      <h3 className='text-capitalize _0ff3d3ee text-center pricing-h2'>{t('successTitle')}</h3>
      <p className=' text-center pricing-p'>
        <Trans
                        i18nKey="successDescription"
                        components={{ 1: <br /> }}
                      />
      </p>
      <div className='container dc4e95e1 font-family-poppins process-section pt-4'>
        <div>
          <div className='row'>
            <div className='col-md-3'>
              <h3 className='_0ff3d3ee success-h2'><CountUp end={1000} duration={2000} isPercentage={false} />+</h3>
              <h4 className='success-h2 pe-3 r-color' >{t('landlords')}</h4>
            </div>
            <div className='col-md-3'>
              <h3 className='_0ff3d3ee success-h2'><CountUp end={10000} duration={2000} isPercentage={false} />+</h3>
              <h4 className='success-h2 pe-5 r-color'>{t('tenants')}</h4>
            </div>
            <div className='col-md-3'>
              <h3 className='_0ff3d3ee success-h2'><CountUp end={50000} duration={2000} isPercentage={false} />+</h3>
              <h4 className='success-h2 r-color' >{t('totalPropertiesManaged')}</h4>
            </div>
            <div className='col-md-3'>
              <h3 className='_0ff3d3ee success-h2'><CountUp end={30000} duration={2000} isPercentage={false} />+</h3>
              <h4 className='success-h2 r-color' >{t('propertiesListed')}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SuccessPage);
