import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface SEOProps {
  title: string;
  description: string;
  keywords: string;
  author: string;
  image: string;
  url: string;
  organizationSchema: any;
}

const SEO: React.FC<SEOProps> = ({ title, description, keywords, author, image, url, organizationSchema }) => {
  const { t } = useTranslation();

  const getString = (key: string, defaultValue: string) => {
    const result = t(key, defaultValue);
    return typeof result === 'string' ? result : defaultValue;
  };

  const defaultImage = "https://resx.mumtalikati.com/img/mumtalikati-logo.png";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={getString('author', author)} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@mumtalikati" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || defaultImage} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image || defaultImage} />
      <link rel="canonical" href={`https://www.mumtalikati.com${window.location.pathname}`} />
      <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>
    </Helmet>
  );
};

export default SEO;
