import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './package.scss';

const plans = [
  {
    id: 1,
    name: 'basic',
    price: '1.000',
    unit: 'Per Unit',
    desc: 'Gain access to essential features to get started on your real estate journey!',
    features: [
      'upTo20Units',
      'monthlySubscription',
      'onDemandServices',
    ],
  },
  {
    id: 2,
    name: 'extended',
    price: '0.750',
    unit: 'Per Unit',
    desc: 'Gain exclusive features and benefits for a top-tier real estate experience!',
    features: [
      'upTo100Units',
      'monthlySubscription',
      'onDemandServices',
    ],
  },
  {
    id: 3,
    name: 'premium',
    price: '0.500',
    unit: 'Per Unit',
    desc: 'Gain exclusive features and benefits for a top-tier real estate experience!',
    features: [
      'upTo1000Units',
      'monthlySubscription',
      'onDemandServices',
    ],
  },
];

const PlanCard = ({ plan, t }) => (
  <div className='col-md-4 pb-4'>
    <div className={`card px-5 py-4 } ${plan.id === 2 ? "card-bgcolor border-0" : "border-0"}`}>
      <div className='card-body'>
        <span className='pe-3 succss-span'>{plan.id < 10 ? `0${plan.id}.` : `${plan.id}.`}</span>
        <span className='succss-span-1'>{t(plan.name)}</span>
        <h3 className='text-start pt-2 _0ff3d3ee r-color'>
          <sup className='success-sup pe-2'>RO</sup>{plan.price}
          <span className='succss-span-1'>/{t(plan.unit)}</span>
        </h3>
        <p className='pt-2'>{t(plan.desc)}</p>
        <h4 className='pt-2 success-included'>{t('whatsIncluded')}</h4>
        {plan.features.map((feature, index) => (
          <div className='pt-4' key={index}>
            <img width="20" height="20" src="https://resx.mumtalikati.com/img/CheckCircleicon.png"
              srcSet="https://resx.mumtalikati.com/img/CheckCircleicon.png" loading="lazy" alt={t('exploreProperties')} />
            <span className='ps-2 pe-4'>{t(feature)}</span>
          </div>
        ))}
        <div className='pt-4 text-center'>
          <button className={`px-5 py-2 ${plan.id === 1 ? "success-included-btn" : "success-included-btn-1"}`}>{t('getStarted')}</button>
        </div>
      </div>
    </div>
  </div>
);

const Packages = () => {
  const { t } = useTranslation();


  return (
    <div className='bg-package'>
      <h4 className='text-capitalize text-center pricing-h4 pt-5'>{t('pricing')}</h4>
      <h2 className='text-capitalize text-center pricing-h2'>{t('subscriptionPackages')}</h2>
      <p className='text-capitalize text-center pricing-p'>
        <Trans
          i18nKey="subscriptionInfo"
          components={{ 1: <br /> }}
        />

        {/* {t('subscriptionInfo')} */}
      </p>
      <div className='text-center succss-radio'>
        <img width="20" height="20" src={`${process.env.REACT_APP_Asset_URL}/img/Ellipse.png`} loading="lazy" alt={t('exploreProperties')} /><span className='ps-2 pe-4'>{t('monthly')}</span>
        <img width="20" height="20" src={`${process.env.REACT_APP_Asset_URL}/img/Ellipse1.png`} loading="lazy" alt={t('exploreProperties')} /><span className='ps-2'>{t('yearly')}</span>
      </div>
      <div className='container dc4e95e1 font-family-poppins'>
        <div className='row'>
          {plans.map(plan => <PlanCard key={plan.id} plan={plan} t={t} />)}
        </div>
        <div className='border-0 card py-5 rounded-bottom-0 rounded-end-3 rounded-top-4 rounded-start-0'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-6 text-center'>
                <h4 className={`succss-span-1`}>{t('customPlan')}</h4>
              </div>
              <div className='col-md-6 text-center'>
                <button className='book-btn'>{t('bookCall')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Packages);
