import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import HomeFilter from './homeFilter/HomeFilter';
import SEO from './SEO';

const Home = () => {
  const location = useLocation();
  const organizationSchema = useMemo(() => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    "image": [
      "https://resx.mumtalikati.com/img/mumtalikatilogred.png",
      "https://resx.mumtalikati.com/img/mumtalikatilogred.png",
      "https://resx.mumtalikati.com/img/mumtalikatilogred.png"
    ],
    "name": "Mumtalikati: Properties for Buy, Rent and Sale in Oman",
    "sameAs": [
      "https://www.facebook.com/Mumtalikati",
      "https://www.linkedin.com/company/mumtalikati/",
      "https://www.instagram.com/mumtalikati/",
      "https://www.youtube.com/@mumtalikatirealestateporta546"
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "GCC U11, B360, South Mawaleh",
      "addressLocality": "Muscat",
      "addressRegion": "Muscat Governorate",
      "postalCode": "121",
      "addressCountry": {
        "@type": "Country",
        "name": "Oman"
      }
    },
    "foundingDate": "2010",
    "founder": [
      {
        "@type": "Person",
        "name": "Faheem Aslam"
      },
      {
        "@type": "Person",
        "name": "Adil Zaheer"
      }
    ],
    "employee": [
      {
        "@type": "Person",
        "name": "Faheem Aslam",
        "jobTitle": "CEO"
      }
    ],
    "numberOfEmployees": {
      "@type": "QuantitativeValue",
      "minValue": "1001",
      "maxValue": "5000"
    },
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "4",
        "bestRating": "5"
      },
      "author": {
        "@type": "Person",
        "name": "Shahbaz Ahmad"
      },
       "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": 4.4,
        "reviewCount": 89
      },  "offers": {
        "@type": "Offer",
        "url": "https://example.com/anvil",
        "priceCurrency": "USD",
        "price": 119.99,
        "priceValidUntil": "2024-11-20",
        "itemCondition": "https://schema.org/UsedCondition",
        "availability": "https://schema.org/InStock"
      }
    },
    "location":{
      "@type": "Place",
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 21.492277944040087,
        "longitude": 55.95424600577483
      }
    },
    "url": "https://www.mumtalikati.com/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://resx.mumtalikati.com/img/mumtalikatilogred.png"
    },
    "telephone": "+968 99160109",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "http://schema.org/Monday",
          "http://schema.org/Tuesday",
          "http://schema.org/Wednesday",
          "http://schema.org/Thursday",
          "http://schema.org/Sunday"
        ],
        "opens": "09:00",
        "closes": "18:00"
      }
    ]
  }), []);
  const defaultSeoProps = useMemo(() => ({
    title: "Mumtalikati.com: Oman Property Real Estate | Sell | Buy | Rent",
    description: "Mumtalikati offers the best residential and commercial properties for buy, rent, or sale in Oman. From apartments and villas to offices and shops, find the perfect property with ease.",
    keywords: "Buy Properties Oman, Rent Properties Oman, Sell Properties Oman, Properties in Oman, Oman Real Estate, Real Estate Oman, Best Real Estate Services in Oman, Best Properties Near Me, Cheap Properties, Property for Rent in Oman, Property for Sale in Oman, 1 BHK Apartments in Muscat, 3 BHK Apartments for Rent in Bousher, Commercial Buildings in Oman.",
    author: "Mumtalikati",
    image: "https://resx.mumtalikati.com/img/mumtalikati-logo.png",
    url: "https://www.mumtalikati.com/",
    organizationSchema: organizationSchema
  }), []);

  const seoPropsByPath = useMemo(() => ({
    '/findAgent': {
      title: "Find Agents in Oman",
      description: "Explore trusted real estate agents in Oman on Mumtalikati. Whether buying, selling, or renting, connect with experienced agents for expert guidance.",
      keywords: "Find Agents Oman, Real Estate Agents Oman, Property Agents Oman, Oman Real Estate, Real Estate Oman, Best Agents Oman, Oman Property, Find properties in Oman with agents, Oman property market agents, Expert real estate advice in Oman, Local real estate agents in Muscat, Real estate services in Oman, Oman Property, How to find real estate agents in Oman",
      author: "Mumtalikati",
      image: "https://resx.mumtalikati.com/img/mumtalikati-logo.png",
      url: "https://www.mumtalikati.com/",
      organizationSchema: organizationSchema

    },
    '/propertyCare': {
      title: "Oman Property Listings: Buy, Rent & Sale",
      description: "Find a diverse range of property listings, including 1-BHK, 2-BHK, 3-BHK, 4-BHK, and 5-BHK apartments, flats, commercial buildings, and more in Oman on Mumtalikati.",
      keywords: "property, management, AI, Oman, buy, rent, sale",
      author: "Mumtalikati",
      image: "https://resx.mumtalikati.com/img/mumtalikati-logo.png",
      url: "https://www.mumtalikati.com/"
    }
  }), []);

  const [seoProps, setSeoProps] = useState(defaultSeoProps);

  useEffect(() => {
    const pathname = location.pathname;
    setSeoProps(seoPropsByPath[pathname] || defaultSeoProps);
  }, [location.pathname, seoPropsByPath, defaultSeoProps]);
  
  return (
    <div>
      <SEO {...seoProps} />
      <HomeFilter />
    </div>
  );
};

export default Home;
