// PropertyModels.ts

export interface IPropertyMasterType {
    masterType: number;
    desc: string;
    url: string | null;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;
  }
  
  export interface IPropertySubType {
    subType: number;
    desc: string;
    url: string;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;
  }
  
  export interface IProperty {
    propertyMasterTypeID: number;
    propertyMasterType: IPropertyMasterType;
    propertySubTypeID: number;
    propertySubType: IPropertySubType;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;
  }
  export class PropertyMasterType implements IPropertyMasterType {
    masterType: number;
    desc: string;
    url: string | null;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;
  
    constructor(data: IPropertyMasterType) {
      this.masterType = data.masterType;
      this.desc = data.desc;
      this.url = data.url;
      this.id = data.id;
      this.status = data.status;
      this.statusCode = data.statusCode;
      this.createdTime = data.createdTime;
      this.modifyTime = data.modifyTime;
      this.isCreatedBy = data.isCreatedBy;
      this.isUpdatedBy = data.isUpdatedBy;
    }
  }
  
  export class PropertySubType implements IPropertySubType {
    subType: number;
    desc: string;
    url: string;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;
  
    constructor(data: IPropertySubType) {
      this.subType = data.subType;
      this.desc = data.desc;
      this.url = data.url;
      this.id = data.id;
      this.status = data.status;
      this.statusCode = data.statusCode;
      this.createdTime = data.createdTime;
      this.modifyTime = data.modifyTime;
      this.isCreatedBy = data.isCreatedBy;
      this.isUpdatedBy = data.isUpdatedBy;
    }
  }
  
  export class Property implements IProperty {
    propertyMasterTypeID: number;
    propertyMasterType: PropertyMasterType;
    propertySubTypeID: number;
    propertySubType: PropertySubType;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;
  
    constructor(data: IProperty) {
      this.propertyMasterTypeID = data.propertyMasterTypeID;
      this.propertyMasterType = new PropertyMasterType(data.propertyMasterType);
      this.propertySubTypeID = data.propertySubTypeID;
      this.propertySubType = new PropertySubType(data.propertySubType);
      this.id = data.id;
      this.status = data.status;
      this.statusCode = data.statusCode;
      this.createdTime = data.createdTime;
      this.modifyTime = data.modifyTime;
      this.isCreatedBy = data.isCreatedBy;
      this.isUpdatedBy = data.isUpdatedBy;
    }
  }
  // PropertyModels.ts

export interface IWilayat {
    name: string;
    shortname: string;
    governorateID: number;
    governorate: null | string; // Assuming governorate can be null or a string
    id: number;
    status: number;
    statusCode: null | string; // Assuming statusCode can be null or a string
    createdTime: null | string; // Assuming createdTime can be null or a string
    modifyTime: null | string; // Assuming modifyTime can be null or a string
    isCreatedBy: null | string; // Assuming isCreatedBy can be null or a string
    isUpdatedBy: null | string; // Assuming isUpdatedBy can be null or a string
  }
  // PropertyModels.ts

export class Wilayat implements IWilayat {
    name: string;
    shortname: string;
    governorateID: number;
    governorate: null | string;
    id: number;
    status: number;
    statusCode: null | string;
    createdTime: null | string;
    modifyTime: null | string;
    isCreatedBy: null | string;
    isUpdatedBy: null | string;
  
    constructor(data: IWilayat) {
      this.name = data.name;
      this.shortname = data.shortname;
      this.governorateID = data.governorateID;
      this.governorate = data.governorate;
      this.id = data.id;
      this.status = data.status;
      this.statusCode = data.statusCode;
      this.createdTime = data.createdTime;
      this.modifyTime = data.modifyTime;
      this.isCreatedBy = data.isCreatedBy;
      this.isUpdatedBy = data.isUpdatedBy;
    }
  }
  // PropertyModels.ts


// PropertyModels.ts

export interface IWilayatBuildingTop8 {
    totalPropertyInWilayat: number;
    wilayatID: number;
    name: string;
    imageURL: string;
  }
  
  export class WilayatBuildingTop8 implements IWilayatBuildingTop8 {
    totalPropertyInWilayat: number;
    wilayatID: number;
    name: string;
    imageURL: string;
  
    constructor(data: IWilayatBuildingTop8) {
        debugger
      this.totalPropertyInWilayat = data.totalPropertyInWilayat;
      this.wilayatID = data.wilayatID;
      this.name = data.name;
      this.imageURL = data.imageURL;
    }
  }

  // PropertyModels.ts

// Define the interface for the area
export interface IArea {
    areaId: number;
    areaName: string;
    desc: string;
  }
  export interface IListingPurpose {
    listingPurposeType: number;
    desc: string;
    id: number;
  }
  export class ListingPurpose  implements IListingPurpose {
    listingPurposeType: number;
    desc: string;
    id: number;
    constructor(data: IListingPurpose) {
      this.listingPurposeType = data.listingPurposeType;
      this.desc = data.desc;
      this.id = data.id;
    }
  }
  // Define the interface for the wilayat with areas (Type 1)
  export interface IWilayatWithAreas {
    wilayatId: number;
    wilayatName: string;
    propertyMasterSubTypeID: number;
    propertyMasterTypeID: number;
    wilayatAreas: IArea[] | null; // Areas can be null
  }
  
  // Define the class for the wilayat with areas
  export class WilayatWithAreas implements IWilayatWithAreas {
    wilayatId: number;
    wilayatName: string;
    propertyMasterSubTypeID: number;
    propertyMasterTypeID: number;
    wilayatAreas: IArea[] | null;
  
    constructor(data: IWilayatWithAreas) {
      this.wilayatId = data.wilayatId;
      this.wilayatName = data.wilayatName;
      this.propertyMasterSubTypeID = data.propertyMasterSubTypeID;
      this.propertyMasterTypeID = data.propertyMasterTypeID;
      this.wilayatAreas = data.wilayatAreas;
    }
  }
  
  // Define the interface for the wilayat without areas (Type 2)
  export interface IWilayatWithoutAreas {
    wilayatId: number;
    wilayatName: string;
    propertyMasterSubTypeID: number;
    propertyMasterTypeID: number;
    wilayatAreas: null; // Always null for Type 2
  }
  
  // Define the class for the wilayat without areas
  export class WilayatWithoutAreas implements IWilayatWithoutAreas {
    wilayatId: number;
    wilayatName: string;
    propertyMasterSubTypeID: number;
    propertyMasterTypeID: number;
    wilayatAreas: null;
  
    constructor(data: IWilayatWithoutAreas) {
      this.wilayatId = data.wilayatId;
      this.wilayatName = data.wilayatName;
      this.propertyMasterSubTypeID = data.propertyMasterSubTypeID;
      this.propertyMasterTypeID = data.propertyMasterTypeID;
      this.wilayatAreas = data.wilayatAreas;
    }
  }
  
  // Define the interface for the response of Type 1
  export interface IPopularSearchPropertiesType1 {
    wilayatList: IWilayatWithAreas[];
  }
  
  // Define the interface for the response of Type 2
  export interface IPopularSearchPropertiesType2 {
    name: string;
    wilayatList: IWilayatWithoutAreas[];
  }
  export interface IAllArea {
    id: number;
    name: string;
    wilayatId: number;
  }
  export class AllAreas implements IAllArea {
    id: number;
    name: string;
    wilayatId: number;
  
    constructor(data: IAllArea) {
      this.id = data.id;
      this.name = data.name;
      this.wilayatId = data.wilayatId;
    }
  }
  // PropertyModels.ts

export interface IPropertyFilter {
    listingPurposesID: number;
    propertyMasterTypeID: number;
    propertyMasterSubTypeID: number;
    pageNumber: number;
    rowsNumbers: number;
    wilayatID: number | null; // Assuming wilayatID can be null
    areaID: number | null; // Assuming areaID can be null
    bedRoomList: number[]; // Assuming this is an array of bedroom counts
    maxPrice: number;
    minPrice: number;
  }
  
  export class PropertyFilter implements IPropertyFilter {
    listingPurposesID: number;
    propertyMasterTypeID: number;
    propertyMasterSubTypeID: number;
    pageNumber: number;
    rowsNumbers: number;
    wilayatID: number | null;
    areaID: number | null;
    bedRoomList: number[];
    maxPrice: number;
    minPrice: number;
  
    constructor(data: IPropertyFilter) {
      this.listingPurposesID = data.listingPurposesID;
      this.propertyMasterTypeID = data.propertyMasterTypeID;
      this.propertyMasterSubTypeID = data.propertyMasterSubTypeID;
      this.pageNumber = data.pageNumber;
      this.rowsNumbers = data.rowsNumbers;
      this.wilayatID = data.wilayatID;
      this.areaID = data.areaID;
      this.bedRoomList = data.bedRoomList;
      this.maxPrice = data.maxPrice;
      this.minPrice = data.minPrice;
    }
  }
  // PropertyModels.ts

// Define the interface for the property listing
export interface IPropertyListing {
    rowsIndex: number | null;
    rownumberId: number;
    propertyMasterID: number;
    unitCategoryID: number;
    listingPurposeID: number;
    propertyMasterSubType: number;
    propertyMasterName: string;
    propertyUnitDescription: string;
    rentPrice: number | null;
    sellPrice: number | null;
    addressStr: string;
    landLordID: number;
    totalCount: number;
    plotNumber: string;
    propertyMasterTypeID: number;
    propertyUnitID: number;
    unitName: string;
    pageNumber: number | null;
    rowsNumbers: number | null;
    bedRoom: number;
    bathRoom: number;
    sqft: number;
    contact: string;
    landlordName: string;
    createddate: string | null;
    imageString: string | null;
  }
  
  // Define the interface for the API response
  export interface IPropertyResponse {
    message: string;
    statusCode: number;
    totalCount: number;
    data: IPropertyListing[];
  }
  
  // Define the class for the property listing
  export class PropertyListing implements IPropertyListing {
    rowsIndex: number | null;
    rownumberId: number;
    propertyMasterID: number;
    unitCategoryID: number;
    listingPurposeID: number;
    propertyMasterSubType: number;
    propertyMasterName: string;
    propertyUnitDescription: string;
    rentPrice: number | null;
    sellPrice: number | null;
    addressStr: string;
    landLordID: number;
    totalCount: number;
    plotNumber: string;
    propertyMasterTypeID: number;
    propertyUnitID: number;
    unitName: string;
    pageNumber: number | null;
    rowsNumbers: number | null;
    bedRoom: number;
    bathRoom: number;
    sqft: number;
    contact: string;
    landlordName: string;
    createddate: string | null;
    imageString: string | null;
  
    constructor(data: IPropertyListing) {
      this.rowsIndex = data.rowsIndex;
      this.rownumberId = data.rownumberId;
      this.propertyMasterID = data.propertyMasterID;
      this.unitCategoryID = data.unitCategoryID;
      this.listingPurposeID = data.listingPurposeID;
      this.propertyMasterSubType = data.propertyMasterSubType;
      this.propertyMasterName = data.propertyMasterName;
      this.propertyUnitDescription = data.propertyUnitDescription;
      this.rentPrice = data.rentPrice;
      this.sellPrice = data.sellPrice;
      this.addressStr = data.addressStr;
      this.landLordID = data.landLordID;
      this.totalCount = data.totalCount;
      this.plotNumber = data.plotNumber;
      this.propertyMasterTypeID = data.propertyMasterTypeID;
      this.propertyUnitID = data.propertyUnitID;
      this.unitName = data.unitName;
      this.pageNumber = data.pageNumber;
      this.rowsNumbers = data.rowsNumbers;
      this.bedRoom = data.bedRoom;
      this.bathRoom = data.bathRoom;
      this.sqft = data.sqft;
      this.contact = data.contact;
      this.landlordName = data.landlordName;
      this.createddate = data.createddate;
      this.imageString = data.imageString;
    }
  }
  
  // Define the class for the API response
  export class PropertyResponse implements IPropertyResponse {
    message: string;
    statusCode: number;
    totalCount: number;
    data: PropertyListing[];
  
    constructor(data: IPropertyResponse) {
      this.message = data.message;
      this.statusCode = data.statusCode;
      this.totalCount = data.totalCount;
      this.data = data.data.map(item => new PropertyListing(item)); // Map to PropertyListing instances
    }
  }
  export interface IPropertyUnitId {
    PropertyUnitID: number;
  }
  export class PropertyUni implements IPropertyUnitId {
    PropertyUnitID: number;

  
    constructor(data: IPropertyUnitId) {
      this.PropertyUnitID = data.PropertyUnitID;
    }
  }
  // PropertyModels.ts

export interface IPropertyUnit {
    PropertyUnitID: number;
    unitCategoryID: number;
    listingPurposeID: number;
    propertyMasterSubType: number;
    propertyMasterName: string;
    propertyUnitDescription: string;
    rentPrice: number | null;
    sellPrice: number | null;
    addressStr: string;
    landLordID: number;
    plotNumber: string;
    propertyMasterTypeID: number;
    propertyUnitID: number;
    unitName: string;
    bedRoom: number;
    bathRoom: number;
    sqft: number;
    contact: string;
    landlordName: string;
    createddate: string | null;
    imageString: string | null;
  }
  
  export class PropertyUnit implements IPropertyUnit {
    PropertyUnitID: number;
    unitCategoryID: number;
    listingPurposeID: number;
    propertyMasterSubType: number;
    propertyMasterName: string;
    propertyUnitDescription: string;
    rentPrice: number | null;
    sellPrice: number | null;
    addressStr: string;
    landLordID: number;
    plotNumber: string;
    propertyMasterTypeID: number;
    propertyUnitID: number;
    unitName: string;
    bedRoom: number;
    bathRoom: number;
    sqft: number;
    contact: string;
    landlordName: string;
    createddate: string | null;
    imageString: string | null;
  
    constructor(data: IPropertyUnit) {
        debugger
      this.PropertyUnitID = data.PropertyUnitID;
      this.unitCategoryID = data.unitCategoryID;
      this.listingPurposeID = data.listingPurposeID;
      this.propertyMasterSubType = data.propertyMasterSubType;
      this.propertyMasterName = data.propertyMasterName;
      this.propertyUnitDescription = data.propertyUnitDescription;
      this.rentPrice = data.rentPrice;
      this.sellPrice = data.sellPrice;
      this.addressStr = data.addressStr;
      this.landLordID = data.landLordID;
      this.plotNumber = data.plotNumber;
      this.propertyMasterTypeID = data.propertyMasterTypeID;
      this.propertyUnitID = data.propertyUnitID;
      this.unitName = data.unitName;
      this.bedRoom = data.bedRoom;
      this.bathRoom = data.bathRoom;
      this.sqft = data.sqft;
      this.contact = data.contact;
      this.landlordName = data.landlordName;
      this.createddate = data.createddate;
      this.imageString = data.imageString;
    }
  
    // Method to convert the instance to a plain object for posting
    // toPostData(): IPropertyUnit {
    //   return {
    //     PropertyUnitID: this.PropertyUnitID,
    //     unitCategoryID: this.unitCategoryID,
    //     listingPurposeID: this.listingPurposeID,
    //     propertyMasterSubType: this.propertyMasterSubType,
    //     propertyMasterName: this.propertyMasterName,
    //     propertyUnitDescription: this.propertyUnitDescription,
    //     rentPrice: this.rentPrice,
    //     sellPrice: this.sellPrice,
    //     addressStr: this.addressStr,
    //     landLordID: this.landLordID,
    //     plotNumber: this.plotNumber,
    //     propertyMasterTypeID: this.propertyMasterTypeID,
    //     propertyUnitID: this.propertyUnitID,
    //     unitName: this.unitName,
    //     bedRoom: this.bedRoom,
    //     bathRoom: this.bathRoom,
    //     sqft: this.sqft,
    //     contact: this.contact,
    //     landlordName: this.landlordName,
    //     createddate: this.createddate,
    //     imageString: this.imageString,
    //   };
    // }
  }
  // PropertyModels.ts


  
