import axios from 'axios';
// import api from "./interceptors";
// export const getWaliyatdes = async () => {
//   try {
//       const response = await api.get(`Setup/GetWilayat`);
//       // if (response.status===200) {
//       //     throw new Error('Failed to fetch GetPropertyMasterTypes');
//       // }
//       return await response.data;
//   } catch (error) {
//       console.error('Error fetching GetPropertyMasterTypes:', error);
//       throw error; 
//   }
// };
// export async function getWaliyatdesc(id) {
//     try {
//         const waliyatdes = await getWaliyatdes();
//         if (waliyatdes) {
//             const deselist = waliyatdes.find((a) => a.id === id);
//             return deselist?.name;
//         }
//         return null;
//     } catch (error) {
//         console.error('Error in getWaliyatdesc:', error);
//         throw error;
//     }
// }

// export async function postPropertyFilter(propertyFilter) {
//     try {
//       const response = await api.post(`PropertyFilter/PropertyFilterIncludeBedroomFilter`, 
//         propertyFilter
//       );
  
//       // if (response.status===200) {
//       //   throw new Error('Failed to fetch property filter: ' + response.status);
//       // }
  
//       const data = await response.data;
//       return data;
//     } catch (error) {
//       throw new Error(error.message || 'Error');
//     }
//   }
//   export const getWilayatProperty = async (listingPurposeID) => {
//     try {
//         const response = await api.get(`WilayatProperty/WilayatBuildingtop10List?listingPurposeID=${listingPurposeID}`);
     
//         return await response.data;
//     } catch (error) {
//         console.error('Error fetching WilayatBuildingtop10List:', error);
//         throw error; 
//     }
//   };
//   export const getPopularSearchProperty = async (listingPurposeID,PropertyMasterSubTypeId,Type) => {
//     try {
//         const response = await api.get(`PopularSearch/PopularSearchProperties?ListingPurposeId=${listingPurposeID}&PropertyMasterSubTypeId=${PropertyMasterSubTypeId}&Type=${Type}`);
       
        
//         return await response.data;
//     } catch (error) {
//         console.error('Error fetching WilayatBuildingtop10List:', error);
//         throw error; 
//     }
//   };
//   export const getPropertyFeature = async (id, unitCatID) => {
//     try {
//         const response = await api.get(`PropertyFeature/GetPropertyByUnitCategoryID?PropertyMasterID=${id}&UnitCategoryID=${unitCatID}`);
       
        
//         return await response.data;
//     } catch (error) {
//         console.error('Error fetching features:', error);
//         throw error; 
//     }
//   };
import { PropertyResponse, PropertyUnit, WilayatWithAreas, WilayatWithoutAreas } from "../FilterProperty/PropertyModels";
import api from "./interceptors";

/**
 * Fetch the list of Wilayats
  @returns {Promise<Array>} 
 * @throws {Error} 
 */
export const fetchWilayats = async () => {
  try {
    const response = await api.get('Setup/GetWilayat');
    return response.data;
  } catch (error) {
    console.error('Error fetching Wilayats:', error);
    throw error;
  }
};

/**
 * Get the name of a specific Wilayat by ID
 * @param {number} id - Wilayat ID
 * @returns {Promise<string|null>} Name of the Wilayat or null if not found
 * @throws {Error} If the API call or processing fails
 */
export const fetchWilayatNameById = async (id) => {
  try {
    const wilayats = await fetchWilayats();
    const wilayat = wilayats.find((wilayat) => wilayat.id === id);
    return wilayat?.name || null;
  } catch (error) {
    console.error('Error fetching Wilayat name by ID:', error);
    throw error;
  }
};
// export const fetchWilayatNameByName = async (name) => {
//   try {
//     // Fetch the list of Wilayats
//     const wilayats = await fetchWilayats();
// debugger
//     // Normalize the input name and compare it with normalized Wilayat names
//     const normalizedInputName = name.replace(/-/g, '').replace(/\s+/g, '').toLowerCase();
//     const wilayat = wilayats.find((wilayat) =>
//       wilayat?.name.replace(/-/g, '').replace(/\s+/g, '').toLowerCase() === normalizedInputName
//     );

//     // Return the Wilayat ID or null if not found
//     return wilayat?.id || null;
//   } catch (error) {
//     console.error('Error fetching Wilayat name by name:', error);
//     throw error;
//   }
// };

/**
 * Post property filter data and get filtered properties
 * @param {Object} propertyFilter - Property filter data
 * @returns {Promise<Array>} Filtered properties
 * @throws {Error} If the API call fails
 */
export const postPropertyFilter = async (propertyFilter) => {
  try {
    const response = await api.post('PropertyFilter/PropertyFilterIncludeBedroomFilter', propertyFilter);
    return new PropertyResponse(response.data);
  } catch (error) {
    console.error('Error posting property filter:', error);
    throw new Error(error.message || 'Error');
  }
};
export const postPropertyFilterName = async (propertyFilter) => {
  try {
    const response = await api.post('PropertyFilter/PropertyFilterByParamName', propertyFilter);
    return response.data;
  } catch (error) {
    console.error('Error posting property filter:', error);
    throw new Error(error.message || 'Error');
  }
};
export const GetBlog = async (pagenumber) => {
  try {
    const response = await api.get(`Blog/GetBlog?Status=1&PageNumber=${pagenumber}&RowNumber=10`);
    return response.data;
  } catch (error) {
    console.error('Error fetching  BLOGS:', error);
    throw new Error(error.message || 'Error');
  }
};
export const GetBlogDetail = async (blogID:number) => {
  try {
    debugger
    const response = await api.get(`Blog/GetBlogDetail?blogID=${blogID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching  BLOGS:', error);
    throw new Error(error.message || 'Error');
  }
};
export const GetMarkDownFile = async (url:string) => {
  try {
    debugger
    const response = await axios.get(url,{ timeout: 10000 });
    return response.data;
  } catch (error) {
    console.error('Error fetching BLOGS:', error);
    if (error.response) {
      console.error('Response Error:', error.response.data);
    } else if (error.request) {
      console.error('Request Error:', error.request);
    } else {
      console.error('Error Message:', error.message);
    }
    throw new Error(error.message || 'Error');
  }
};
/**
 * Get the top 10 Wilayat properties based on listing purpose ID
 * @param {number} listingPurposeID - Listing purpose ID
 * @returns {Promise<Array>} List of Wilayat properties
 * @throws {Error} If the API call fails
 */
export const fetchTop10WilayatProperties = async (listingPurposeID) => {
  try {
    const response = await api.get(`WilayatProperty/WilayatBuildingtop10List?listingPurposeID=${listingPurposeID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching top 10 Wilayat properties:', error);
    throw error;
  }
};

/**
 * Get popular search properties
 * @param {number} listingPurposeID - Listing purpose ID
 * @param {number} propertyMasterSubTypeId - Property master subtype ID
 * @param {string} type - Type of the property
 * @returns {Promise<Array>} List of popular search properties
 * @throws {Error} If the API call fails
 */
export const fetchPopularSearchProperties = async (listingPurposeID, propertyMasterSubTypeId, type) => {
  try {
    const response = await api.get(`PopularSearch/PopularSearchProperties?ListingPurposeId=${listingPurposeID}&PropertyMasterSubTypeId=${propertyMasterSubTypeId}&Type=${type}`);
    if (type === 1) {
      // Map to WilayatWithAreas for Type 1
      return response.data.map((item: any) => new WilayatWithAreas(item));
    } else if (type === 2) {
      // Map to WilayatWithoutAreas for Type 2
      return {
        name: response.data.name,
        wilayatList: response.data.wilayatList.map((item: any) => new WilayatWithoutAreas(item))
      };
    }
    // return response.data;
  } catch (error) {
    console.error('Error fetching popular search properties:', error);
    throw error;
  }
};

/**
 * Get property features by unit category ID
 * @param {number} propertyMasterId - Property master ID
 * @param {number} unitCategoryId - Unit category ID
 * @returns {Promise<Object>} Property features
 * @throws {Error} If the API call fails
 */
export const fetchPropertyFeatures = async (propertyMasterId, unitCategoryId) => {
  try {
    const response = await api.get(`PropertyFeature/GetPropertyByUnitCategoryID?PropertyMasterID=${propertyMasterId}&UnitCategoryID=${unitCategoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property features:', error);
    throw error;
  }
};
/**
 * Get popular search properties
 * @param {number} unitid - unitid  ID
 * @returns {Promise<Array>} List of popular search properties
 * @throws {Error} If the API call fails
 */
export const fetchPropertyCard = async (unitid) => {
  try {
    const response = await api.get(`PropertyDetail/GetPropertyUnitDetailsByUnit?PropertyUnitID=${unitid}`);
    // return response.data.map((item: any) => new PropertyUnit(item));
    console.log("res",response)
    return new PropertyResponse(response.data);
  } catch (error) {
    console.error('Error fetching popular search properties:', error);
    throw error;
  }
};