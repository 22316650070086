import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";

export const CustomAutocompleteMobile = styled(Autocomplete)({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: '40',
      border: "1px solid black",
      borderBottomRightRadius: '1px ',
      borderBottomLeftRadius: '1px',
      borderStartEndRadius: '1px ',
      borderStartStartRadius: ' 1px ',
      height: '40px',
    },
    "&.MuiAutocomplete-root .MuiOutlinedInput-root ": {
      padding: '0px'
    },
    '&.MuiAutocomplete-root': {
      width: '100%',
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: "none",
    },
    '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: "none",
    },
    '&.MuiAutocomplete-root:hover': {
      border: '1px solid black !important',
    },
    '& .Mui-focused fieldset': {
      border: "none",
    },
    '& :focus': {
      backgroundColor: 'none',
      border: 'none !important',
      outline: 'none !important',
    },
    '&:focus-visible': {
      border: 'none !important',
      outline: 'none !important',
    },
    '&:hover': {
      border: '1px solid black !important',
      outline: 'none !important',
      borderRadius: '40',
      borderBottomRightRadius: '1px ',
      borderBottomLeftRadius: '1px',
      borderStartEndRadius: '1px ',
      borderStartStartRadius: ' 1px ',
      height: '40px',
    },
  
  });