import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
export const CustomAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: '40',
      border: "none",
      borderBottomRightRadius: 'none',
      borderBottomLeftRadius: 'none',
      borderStartEndRadius: 'none',
      borderStartStartRadius: '0px',
      borderLeft: 'none',
    },
    '&.MuiInputBase-root-MuiOutlinedInput-root': {
      fontSize: '1.4rem',
    },
    '&.MuiAutocomplete-root': {
      width: '100%',
    },
    '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
      fontSize: '1.4rem',
      padding: '6.5px 4px 0.5px 0px',
    },
    "&.MuiAutocomplete-root .MuiOutlinedInput-root ": {
      padding: '0px'
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: "none",
    },
    '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: "none",
    },
    '&.MuiAutocomplete-root:hover': {
      border: 'none !important',
    },
    '& .Mui-focused fieldset': {
      border: "none",
    },
    '& :focus': {
      backgroundColor: 'none',
      border: 'none !important',
      outline: 'none !important',
    },
    '&:focus-visible': {
      border: 'none !important',
      outline: 'none !important',
    },
    '&:hover': {
      border: 'none !important',
      outline: 'none !important',
      borderRadius: '40',
      borderBottomRightRadius: 'none',
      borderBottomLeftRadius: 'none',
      borderStartEndRadius: 'none',
      borderStartStartRadius: 'none',
      borderLeft: 'none !important',
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-input': {
      fontSize: '1.2rem !important',
      fontWeight: '400',
      color: '#222'
    }
  });