import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import './HomeFilter.scss';
import { useDispatch, useSelector } from "react-redux";
const CiFilter = lazy(() => import("react-icons/ci").then(module => ({ default: module.CiFilter })));
import Modal from '@mui/material/Modal';
import { CustomAutocomplete } from "./CustomAutocomplete"
import { CustomAutocompleteMobile } from "./CustomAutocompleteMobile"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import i18n from "../../reduxstate/i18n.js";
import heroImage from "../../assets/Images/jpeg-optimizer_dark version-012.png";
import heroImageSmallWebP from "../../assets/Images/heroImageSmallWebP.png";
import { Helmet } from 'react-helmet';
const StyledInputAdornment = styled(InputAdornment)({
  paddingRight: 8,
});
import {
  getListingPurpose,
  getPropertySubTypes,
  getPropertyMasterTypes,
  getWaliyat,
  getAllAreas,
  getWilayatBuildingtop8List,
} from "../FilterProperty/SetServices";
import {
  propertylist,
  propertyMasterList,
  propertyWatiyatList,
  propertySubTypesList,
  dataAllAreas,
  listingsData,
  popularAreasData,
  getwilayatBuildingtop8
} from "../../reduxstate/areasListSlice";

function valuetext(value: number) {
  return `${value}°C`;
}
import { RootState } from '../../reduxstate/store';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation } from "react-router-dom";
import { getPropertySubTypeEnum, getPropertySubTypeEnumdeschome, propertyMasterTypeEnumid } from '../model/enum';
import { fetchPopularSearchProperties, fetchWilayatNameById } from '../model/service';
import { Property, PropertyMasterType, Wilayat, WilayatBuildingTop8 } from '../FilterProperty/PropertyModels';
import { Form } from 'react-bootstrap';
import PopularArea from '../Homesections/PopularArea';
import PopularSearches from '../Homesections/PopularSearches';
import BrowseProperty from '../Homesections/Browse-Properties/BrowseProperty';
import { InputAdornment } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Packages from './Packages';
import SuccessPage from './SuccessPage';
interface Area {
  id: number;
  name: string;
  wilayatId: number;
}
const HomeFilter = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const webURL = process.env.REACT_APP_WEBSITE_URL;
  const homePageWebp = `${process.env.REACT_APP_Asset_URL}/img/dark version-012.png`;
  const [listingpurposeid, setListingpurposeid] = useState<number>(1);
  const [hidden, sethidden] = useState(false);
  const [hiddenPropertyMT, sethiddenPropertyMT] = useState(false);
  const [bedpopup, setBedPopUp] = useState(false);
  const [pricepopup, setPricePopUp] = useState(false);
  const [Bedlist, setBedlist] = useState([1, 2, 3, 4, 5]);
  const [subTypeID, setSubTypeID] = useState(null);
  const [selectedValue, setSelectedValue] = useState(t('Any'));
  const [subTypeValue, setSubTypeValue] = useState(t('Any'));
  const [bedValue, setbedValue] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [areaId, setAreaId] = useState<number>(0);
  const [ListID, setListID] = useState(1);
  const dispatch = useDispatch();
  const [waliyatId, setWaliyatId] = useState<number>(null)
  const [maxPrice, setMaxPrice] = useState<number>(175000)
  const [minValue, setMinValue] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [TextFieldValue, setTextFieldValue] = useState(t('Any'));
  const [maxValue, setMaxValue] = useState<number>(175000);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const [min, setmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [top8WilayatList, setTop8WilayatList] = useState(null);
  const [wtype, setWtype] = useState(false);
  const [atype, setAtype] = useState(false);
  function showAlert(message) {
    const alertContainer = document.createElement('div');
    alertContainer.className = 'alert-container';
    alertContainer.textContent = message;
    const closeButton = document.createElement('button');
    // closeButton.textContent = '×';
    closeButton.className = 'close-button';
    closeButton.onclick = () => {
      document.body.removeChild(alertContainer);
    };
    alertContainer.appendChild(closeButton);
    document.body.appendChild(alertContainer);
    setTimeout(() => {
      alertContainer.style.display = 'block';
    }, 50);
    setTimeout(() => {
      alertContainer.style.display = 'none';
      document.body.removeChild(alertContainer);
    }, 3000);
  }
  const handleMinValueChanges = (e) => {
    const newValue = Number(e.target.value);
    setmin(true)
    if (!isNaN(newValue)) {
      if (newValue > 175000) {
        showAlert(
          'Error: Price range should not exceed OMR 175,000. Please review and adjust your selection as necessary.',
        );

      }
      else {
        setMinValue(newValue);
      }

    }
    else {

    }

  };
  const handleMaxValueChange = (e) => {
    const newValue = Number(e.target.value);
    setmin(true)
    if (!isNaN(newValue)) {
      if (newValue > 175000) {
        showAlert(
          'Error: Price range should not exceed OMR 175,000. Please review and adjust your selection as necessary.',
        );

      }
      else {
        setMaxValue(newValue);
      }
    } else {

    }

  };
  const handleFindButtonClick = () => {
    setmin(true)
    if (minValue !== null && maxValue !== null && minValue > maxValue) {
      const temp = minValue;
      setMinValue(maxValue);
      setMaxValue(minValue);
    }
    else {
      if (minValue !== null && maxValue !== null && minValue < maxValue) {
        setMinValue(minValue);
        setMaxValue(maxValue);
      }
      else if (minValue !== null && maxValue !== null && minValue === maxValue) {
        setMinValue(minValue);
        setMaxValue(maxValue);
      }
      else {
        setMinValue(0);
        setMaxValue(175000);
      }
    }
  };

  const switchType = (selectedType) => {
    setListID(selectedType.id);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const propertyList = [{
    listingPurposeType: 1,
    desc: "Rent",
    id: 1
  }, {
    listingPurposeType: 2,
    desc: "Buy",
    id: 2
  }]
  // const propertyList = useSelector((state: RootState) => state.areas.propertyList);
  const propertyCityList = useSelector((state: RootState) => state.areas.propertyWaliyatList);
  const PropertySubTypesList = useSelector((state: RootState) => state.areas.propertySubTypesList);
  const wilayatBuildingtop8List = useSelector((state: RootState) => state.areas.wilayatBuildingtop8List);
  const PropertyMasterTypesList = useSelector((state: RootState) => state.areas.propertyMasterList);
  const popularAreaList = useSelector((state: RootState) => state.areas.popularAreasData);
  // const ListData = useSelector((state: RootState) => state.areas.listingsData);
  const areasList = useSelector((state: RootState) => state.areas.AreasList);
  const areasArray = areasList ? areasList.map((area: Area) => area) : [];
  const [filteredOptions, setFilteredOptions] = useState<Area[]>(areasArray);
  // const selectedOption = filteredOptions.find(option => option.id === areaId );
  // const [selectedOption, setSelectedOption] = useState<Area | null>(null);
  let selectedOption: Area | string = "any";
  if (areaId) {
    const foundOption = filteredOptions.find((option) => option.id === areaId);
    if (foundOption) {
      selectedOption = foundOption;
    }
  } else {
    selectedOption = "any";
  }
  const [popularData, setPopularData] = useState(null);
  const [loadingPopular, setLoadingPopular] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingPopular(true);  // Start loading when fetching data
        const promises = [];
        if (!propertyCityList) {
          promises.push(
            getWaliyat().then((data:Wilayat[]) => dispatch(propertyWatiyatList(data)))
          );
        }
        if (!PropertyMasterTypesList) {
          promises.push(
            getPropertyMasterTypes().then((data: PropertyMasterType[]) => {
              const updatedData = data.map((item) =>
                item.desc === 'ResidentalCommercial' ? { ...item, desc: 'Residental-Commercial' } : item
              );
              dispatch(propertyMasterList(updatedData));
            })
          );
        }

        if (!PropertySubTypesList) {
          promises.push(
            getPropertySubTypes().then((data:Property[]) => {
              const updatedData = data.map((item) => {
                if (
                  item.propertyMasterType.masterType === 1 &&
                  item.propertySubType.desc === "Building"
                ) {
                  return {
                    ...item,
                    propertySubType: {
                      ...item.propertySubType,
                      desc: "Apartment",
                    },
                  };
                }
                return item;
              });
              dispatch(propertySubTypesList(updatedData))
            })
          );
        }

        if (!wilayatBuildingtop8List) {
          promises.push(
            getWilayatBuildingtop8List().then((data:WilayatBuildingTop8) => dispatch(getwilayatBuildingtop8(data)))
          );
        }

        promises.push(
          Promise.all([
            fetchPopularSearchProperties(listingpurposeid, 1, 1),
            fetchPopularSearchProperties(listingpurposeid, 6, 1),
            fetchPopularSearchProperties(listingpurposeid, 1, 2),
            fetchPopularSearchProperties(listingpurposeid, 6, 2),
          ]).then(([data1, data2, data3, data4]) => {
            const popularData = {
              PopularList: data1,
              PopularVillaList: data2,
              popularWilayat: data3,
              popularVilla: data4,
              popularWilayatList: data3.wilayatList,
              popularWilayatsixList: data4.wilayatList,
            };
            setPopularData(popularData);
            dispatch(popularAreasData(popularData));
          })
        );

        await Promise.all(promises);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingPopular(false); // End loading when data fetching is done
        setLoading(false);
      }
    };

    fetchData();
  }, [
    dispatch,
    propertyCityList,
    PropertyMasterTypesList,
    PropertySubTypesList,
    listingpurposeid
  ]);

  useEffect(() => {
    if (!areasList) {
      getData()
    }


  }, [])
  const getData = async () => {
    const response = await getAllAreas();
    if (response) {
      dispatch(dataAllAreas(response));
    }
  };
  const handleSelect = (name, id) => {
    setWtype(true)
    setAtype(false)
    const isSelected = waliyatId === id;
    const newCityID = isSelected ? null : id;
    setSelectedValue((preselectedValue) => (preselectedValue === name ? `${t('Any')}` : name));
    setWaliyatId(newCityID);
    if(!isSelected){
      const getAreaList = areasArray.filter((doc) => doc.wilayatId === newCityID)
      setFilteredOptions(getAreaList)
    }else{
      setFilteredOptions(areasArray)
      setAreaId(0)
    }
    // if (selectedValue !== t('Any')) {
    //   // setFilteredOptions(areasArray)
    //   const getAreaList = areasArray.filter((doc) => doc.wilayatId === id)
  
    // } else {
    //   const getAreaList = areasArray.filter((doc) => doc.wilayatId === id)
    //   setFilteredOptions(getAreaList)
    // }
    sethidden(false);
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      setSubTypeValue(t('Any'));
      setSelectedValue(t('Any'));
      setTextFieldValue(t('Any'));
    };
    handleLanguageChange()
  }, [i18n.language, t]);
  const handleSubTypeSelect = (name, id) => { 
    if (name === 'All') {
      const data = selectedItemId === 1 ? 'Residential' : 'Commercial'
      setSubTypeValue(data);
      setSubTypeID(id)
      sethiddenPropertyMT(false)
      sethidden(false);
    } else {
      setSubTypeValue(name);
      setSubTypeID(id)
      sethiddenPropertyMT(false)
      sethidden(false);
    }

  };
  const handleBedSelect = (id) => {
    debugger
    const isSelected = selectedItems.includes(id);
    let updatedSelectedItems;
    if (isSelected) {
      updatedSelectedItems = selectedItems.filter(item => item !== id);
    } else {
      updatedSelectedItems = [...selectedItems, id];
    }
    updatedSelectedItems.sort((a, b) => a - b);

    setSelectedItems(updatedSelectedItems);

    const newTextFieldValue = updatedSelectedItems.length > 0 ?
      updatedSelectedItems.join(', ') :
      t('Any');

    setTextFieldValue(newTextFieldValue);
    setbedValue(id);
    setBedPopUp(false);

  };
  const handleTabClick = (id) => {
    setSelectedItemId(id);
   
    if (id === 2) {
      setbedValue(null);
      setTextFieldValue('');
      setSelectedItems([]);
    }

  };
  function filterFunction(propertymastertypeid: number, listid: number): any {
    if (PropertySubTypesList) {
      switch (propertymastertypeid) {
        case 1: {
          const ids =
            listid === 1 ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 5, 6, 8, 15];
          return PropertySubTypesList.filter(
            ({ propertyMasterTypeID, propertySubTypeID }) =>
              propertyMasterTypeID === propertymastertypeid &&
              ids.includes(propertySubTypeID)
          );
        }
        case 2: {
          const ids =
            listid === 1 ? [9, 10, 11, 12, 13, 14] : [9, 10, 11, 12, 13, 14, 15];
          return PropertySubTypesList.filter(
            ({ propertyMasterTypeID, propertySubTypeID }) =>
              propertyMasterTypeID === propertymastertypeid &&
              ids.includes(propertySubTypeID)
          );
        }
        case 3: {
          return PropertySubTypesList.filter(
            ({ propertyMasterTypeID }) =>
              propertyMasterTypeID === propertymastertypeid
          );
        }
        default: {
          return [];
        }
      }
    }

  }
  const handleAutocompleteChange = async (event: React.ChangeEvent<{}>, value: Area | null) => {
    if (value) {
      setAtype(true)
      setWtype(false)
      const wilayatID = value.wilayatId
      setAreaId(value.id)
      setWaliyatId(wilayatID)
      const getAreaList = areasArray.filter((doc) => doc.wilayatId === wilayatID)
      setFilteredOptions(getAreaList)
      const waliyatId = await fetchWilayatNameById(wilayatID);
      setSelectedValue(waliyatId);

    }
  };
  const queryParams = new URLSearchParams(location.search);

  const goToPosts = () => {
    const pathname = ListID === 1 ? '/rent' : '/sale';
    const bedroom = TextFieldValue.replace(/\s/g, "");
    const propertySubType = getPropertySubTypeEnum(subTypeID);
    const formatString = (str) => (str || "").trim().replace(/[\/\(\)]/g, "").replace(/\s/g, "-").replace(/-+/g, "-") .toLowerCase();
    const addSearchParam = (value) => { return queryParams.set("type", value); };
    const findAreaName = (id) => {
      const area = filteredOptions?.find((area) => area.id === id);
      return area.name;
    };
    const getPropertyName = (itemId) => {
      switch (itemId) {
        case 1:
          return "property";
        case 2:
          return "commercial";
        case 3:
          return "residental-commercial";
        default:
          return "property";
      }
    };
    
    // const searchParams = [];
    const buildSearchParams = () => {
      debugger
      const searchParams = [];
      if (bedValue && subTypeID && waliyatId && wtype) {
        searchParams.push(`${bedroom}-bedroom-${propertySubType}-in-${formatString(selectedValue)}`.toLowerCase());
        addSearchParam("wilayat")
        queryParams.set("id", waliyatId.toString())
      } else if (bedValue && subTypeID && areaId && atype) {
        searchParams.push(`${bedroom}-bedroom-${propertySubType}-in-${formatString(findAreaName(areaId))}`.toLowerCase());
        addSearchParam("area")
        queryParams.set("id", areaId.toString())
      } else if (bedValue && subTypeID) {
        if (subTypeID === 1) {
          if (selectedItemId === 3) {
            searchParams.push(`${bedroom}-bedroom-${'residental-commercial'}-in-oman`.toLowerCase());
          } else {
            searchParams.push(`${bedroom}-bedroom-${'apartment'}-in-oman`.toLowerCase());
          }
        } else {
          searchParams.push(`${bedroom}-bedroom-${propertySubType}-in-oman`.toLowerCase());
        }
      } else if (subTypeID && waliyatId && wtype) {
        searchParams.push(`${propertySubType}-in-${formatString(selectedValue)}`.toLowerCase());
        addSearchParam("wilayat")
        queryParams.set("id", waliyatId.toString())
      } else if (subTypeID && areaId && atype) {
        searchParams.push(`${propertySubType}-in-${formatString(findAreaName(areaId))}`.toLowerCase());
        addSearchParam("area")
        queryParams.set("id", areaId.toString())
      } else if (bedValue && waliyatId && wtype) {
        searchParams.push(`${bedroom}-bedroom-${getPropertyName(selectedItemId)}-in-${formatString(selectedValue)}`.toLowerCase());
        addSearchParam("wilayat");
        queryParams.set("id", waliyatId.toString())
      } else if (bedValue && areaId && atype) {
        searchParams.push(`${bedroom}-bedroom-${getPropertyName(selectedItemId)}-in-${formatString(findAreaName(areaId))}`.toLowerCase())
        addSearchParam("area")
        queryParams.set("id", areaId.toString())
      } else if (waliyatId && wtype) {
        searchParams.push(`${getPropertyName(selectedItemId)}-in-${formatString(selectedValue)}`.toLowerCase())
        addSearchParam("wilayat")
        queryParams.set("id", waliyatId.toString())
      } else if (areaId && atype) {
        searchParams.push(`${getPropertyName(selectedItemId)}-in-${formatString(findAreaName(areaId))}`.toLowerCase())
        addSearchParam("area")
        queryParams.set("id", areaId.toString())
      } else if (bedValue) {
        searchParams.push(`${bedroom}-bedroom-${getPropertyName(selectedItemId)}-in-oman`.toLowerCase());
      }
      else if (subTypeID) {
        if (subTypeID === 1) {
          searchParams.push(`${'apartment'}-in-oman`.toLowerCase());
        } else {
          searchParams.push(`${propertySubType}-in-oman`.toLowerCase());
        }
      } else {
        searchParams.push(`${getPropertyName(selectedItemId)}-in-oman`.toLowerCase())
      }
      return searchParams;
    }

    if (min) {
      if (minValue !== null && maxValue !== null) {
        queryParams.set("price_min", minValue.toString());
        queryParams.set("price_max", (maxValue === 0 ? 175000 : maxValue).toString());
      }
    }
    const searchParams = buildSearchParams();
    const search = searchParams.join('/');
    navigate({
      pathname: `${pathname}/${search}`,
      search: `?${queryParams.toString()}`,
    });
  };
  const handleClear = () => {
    setListID(1)
    setAreaId(0)
    setWaliyatId(0)
    setSelectedItemId(1)
    setSelectedValue(t('Any'));
    setMinValue(0);
    setMaxValue(null)
    setTextFieldValue(t('Any'))
    setSelectedItems([])
    setSubTypeValue(t('Any'));
    setSubTypeID(null)
    // setSelectedOption(null);
    setFilteredOptions(areasArray);
  }
  const handleButtonClick = (id) => {
    const isSelected = selectedItems.includes(id);
    let updatedSelectedItems;

    if (isSelected) {
      updatedSelectedItems = selectedItems.filter(item => item !== id);
    } else {
      updatedSelectedItems = [...selectedItems, id];
    }
    updatedSelectedItems.sort((a, b) => a - b);
    setSelectedItems(updatedSelectedItems);
    const newTextFieldValue = updatedSelectedItems.length > 0 ?
      updatedSelectedItems.join(', ') :
      t('Any');

    setTextFieldValue(newTextFieldValue);
    setbedValue(id);
  };

  const onPriceRest = () => {
    setMinValue(0);
    setMaxValue(175000);
    setPricePopUp(false)

  };
  const onRest = () => {
    setSubTypeValue(t('Any'));
    setSubTypeID(null)
    sethiddenPropertyMT(false)
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.ad62c515')) {
        sethidden(false);
        setPricePopUp(false);
        sethiddenPropertyMT(false);
        setBedPopUp(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const newListingID = parseInt(event.target.value);
    setListID(newListingID);
  };

  const goToPostResidential = (desc, masterID) => {
    const pathname = ListID === 1 ? 'rent' : 'sale';
    let id = getPropertySubTypeEnumdeschome(desc)
    const propertyType = propertyMasterTypeEnumid(masterID);
    // if(masterID){
    //   queryParams.set("propertytype", masterID.toString());
    // }
    const propertySubType = getPropertySubTypeEnum(id);
    const searchParams = [];
    if (id) searchParams.push(`${propertySubType}-in-oman`.toLowerCase());
    // searchParams.push(`oman`);
    const search = searchParams.join('/');
    const newUrl = `${webURL}${pathname}/${search}`;
    return newUrl;
  };

  const goToPostsbedroom = (bedrooms, id, subid) => {
    const pathname = ListID === 1 ? 'rent' : 'sale';
    const propertySubType = getPropertySubTypeEnum(subid);
    const searchParams = [];

    if (bedrooms) {
      searchParams.push(`${bedrooms}-bedroom-${propertySubType}-in-oman`.toLowerCase());
    } else {
      searchParams.push(`1-bedroom-property-in-oman`);
    }

    // searchParams.push(`oman`);
    const search = searchParams.join('/');

    // Construct the full URL with necessary params
    const newUrl = `${webURL}${pathname}/${search}`;

    return newUrl; // Return the URL string directly to the Link component
  };

  const [inputValue, setInputValue] = useState('');
  const handleSearchWilayat = (e) => {
    setInputValue(e.target.value.toUpperCase());
  };
  const handleChangeWilayat = async (event) => {
    event.preventDefault();
    const waliyatId = parseInt(event.target.value);
    const waliyatdesc = await fetchWilayatNameById(waliyatId);
    setSelectedValue(waliyatdesc)
    setWaliyatId(waliyatId);
    const deselist = areasArray.filter((a) => a.wilayatId === waliyatId);
    setFilteredOptions(deselist)
  };
  const minDistance = 1000;
  const handleSliderChange = (event: Event, newValue: number | number[], activeThumb: number,) => {
    if (Array.isArray(newValue)) {
      setMinValue(newValue[0]);
      setMaxValue(newValue[1]);
    }

  };
  const handleButtonClickpopular = async (id, buttonType) => {
    setListingpurposeid(id);
    // setLoading(true);
    // try {
    //    setListingpurposeid(id);
    //   const [data1, data2, data3, data4] = await Promise.all([
    //     fetchPopularSearchProperties(id, 1, 1),
    //     fetchPopularSearchProperties(id, 6, 1),
    //     fetchPopularSearchProperties(id, 1, 2),
    //     fetchPopularSearchProperties(id, 6, 2),
    //   ]);
    //   const popularData = {
    //     PopularList: data1,
    //     PopularVillaList: data2,
    //     popularWilayat: data3,
    //     popularVilla: data4,
    //     popularWilayatList: data3.wilayatList,
    //     popularWilayatsixList: data4.wilayatList,
    //   };

    //   dispatch(popularAreasData(popularData));
    // } catch (error) {
    //   console.error("Error fetching popular search properties:", error);
    // } finally {
    //   setLoading(false);
    // }
  };
  const items = Array.from({ length: 10 }, (_, index) => `Item ${index + 1}`);

  return (
    <div>
      {/* {loading ? (<Loading />) : ( */}
      <Helmet>
        <link rel="preload" as="image" href={heroImageSmallWebP} />
      </Helmet>
      <div>
        <main>
          <section>
            <div>
              <div className="containers">
                <picture>
                  <source
                    srcSet={`${heroImageSmallWebP} 400w, ${heroImageSmallWebP} 800w, ${homePageWebp} 1200w`}
                    sizes="(max-width: 600px) 400px, (max-width: 1000px) 800px, 1200px"
                    type="image/webp"
                  />
                  <img
                    src={heroImage}
                    alt="properties for Buy, Rent and Sale in Oman"
                    className="hero-img"
                    width="1000"
                    height="314"
                  />
                </picture>

                <div className="center">
                  <div className='searchBar'>
                    <div>
                      <h1 className='main-heading fs-1 font-family-poppins'>
                        {t('exploreProperties')}
                        {/* <Trans
                            i18nKey="exploreProperties"
                            components={{ 1: <br /> }}
                          /> */}
                      </h1>
                    </div>
                    <div>
                      <p className='fs-2 fw-medium pb-4 text-align-justify font-family-poppins'>{t('introducingMumtalikati')}</p>
                    </div>
                    <div className='phone-view'>
                      <div className="card phone-card">
                        <div className="card-body">
                          <Form.Group controlId="formPurpose">
                            <Form.Label>{t('purpose')}</Form.Label>
                            <Form.Control as="select" value={ListID} onChange={handleChange}>
                              {propertyList && propertyList.slice(0, 2).map((purposeList, index) => (
                                <option key={index} value={purposeList.listingPurposeType}>
                                  {purposeList.desc}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <div className="d-flex pt-2  align-items-center">
                            <div className="col-2 ">
                              <div className='ciFilter' onClick={() => handleOpen()}>
                                <Suspense fallback={<div>Loading...</div>}>
                                  <CiFilter className='fs-1 ciFilter-1' />
                                </Suspense>
                              </div>
                            </div>
                            <div className="col-10">
                              <a role="button" className="btn btn-primary find-btn" aria-label="Find button" onClick={goToPosts}>{t('find')}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="type">
                      {propertyList && propertyList.slice(0, 2).map((type) => (
                        <button
                          key={type.id}
                          onClick={() => switchType(type)}
                          className={type.desc && type.id === ListID ? "active" : ""}
                        >
                          {t(type.desc)}
                        </button>
                      ))}
                    </div>
                    <div className='f41f950f '>
                      <div className='da6da704'>
                        <div className="_92a2d3e2 _933f401f FilterDesign2022">
                          <div className="ad62c515 _71877967" data-name="City">
                            <div className="_9dc6d35d"
                              onMouseEnter={() => sethidden(true)}
                              onMouseLeave={() => sethidden(false)}
                            >
                              <label className="_5176f8e1" >{t('wilayat')}</label>
                              <div role="button"
                                aria-haspopup="true" aria-label="City filter"
                                className="e7c6503c"

                                onClick={() => sethidden(!hidden)}
                              >
                                <span className="ef5cccac">
                                  <span className="fontCompensation">{selectedValue}</span>
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" className="eedc221b">
                                  <path className="cls-1" d="M12 6L6 0 0 6h12z">
                                  </path></svg>
                              </div>

                              <div className="_0a772a68 "
                                onMouseEnter={() => sethidden(true)} onMouseLeave={() => sethidden(false)}
                              >
                                {hidden && (
                                  <div className="_3f35dbb9" role="listbox">
                                    <div>
                                      <input className="_78ab53f7"
                                        onChange={handleSearchWilayat}
                                        value={inputValue}
                                      />
                                      <div role="listbox" className="ede17658 customizable-overflow">
                                        {propertyCityList && propertyCityList.map((doc, index) => {
                                          const txtValue = doc.name.toUpperCase();
                                          const displayStyle = txtValue.includes(inputValue) ? '' : 'none';
                                          return (
                                            <span aria-selected="false" className="d92d11c7"
                                              key={index} style={{ display: displayStyle }}>
                                              <button
                                                aria-label={doc.name}
                                                className={`_933a9a61 _5dd5033c ${doc.name && doc.id === waliyatId ? "_8bfc3747" : ""} `}
                                                value={doc.id}
                                                onClick={() => handleSelect(doc.name, doc.id)}
                                              >
                                                {doc.name}
                                              </button>
                                            </span>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    {/* <div className="_16d0f56f">
                                      <button className="_4b391a14" aria-label="Close" onClick={() => sethidden(!hidden)}>{t('close')}</button>
                                    </div> */}
                                  </div>

                                )}
                              </div>
                            </div>
                          </div>
                          <div className="ad62c515 e937c91d">
                            <div className="ad62c515 _749018b1" aria-label="Location filter" data-name="location">
                              <div className="_9dc6d35d">
                                <label className="_5176f8e1">{t('location')}</label>
                                <div className="b9e5541a _4f597301" dir={i18n.language === 'ar' ? 'ltr' : undefined}>
                                  <CustomAutocomplete
                                    onChange={handleAutocompleteChange}
                                    id="free-solo-with-text-demo"
                                    ListboxProps={{
                                      className: "myCustomList"
                                    }}
                                    value={selectedOption || null}
                                    options={filteredOptions}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(
                                      option: {
                                        id: number;
                                        name: string;
                                        wilayatId: number;
                                      } | null
                                    ) =>
                                      option && option.name ? option.name : `${t("All")}`
                                    }
                                    // getOptionLabel={(option: { id: number; name: string; wilayatId: number }) => option.name}
                                    freeSolo
                                    renderInput={(params) =>
                                      <TextField id="autocompleteInput" label={'...'} {...params} />}
                                    renderOption={(props, option: { id: number; name: string; wilayatId: number }) => (
                                      <li className='customizable-overflow' {...props} key={option.id} >
                                        <span>{option.name}</span>
                                      </li>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <a  role="button" className="c3901770 _22dc5e0a" aria-label="Find button" onClick={(e) => {
                            handleFindButtonClick();
                            goToPosts();
                          }}>{t('find')}</a>
                        </div>
                        <div className='_92a2d3e2 _40898f5f FilterDesign2022'>
                          <div className="ad62c515 _71a096cd" data-name="property type">
                            <div className="_9dc6d35d" onMouseEnter={() => sethiddenPropertyMT(true)} onMouseLeave={() => sethiddenPropertyMT(false)} >
                              <label className="_5176f8e1" >{t('propertyType')}</label>
                              <div role="button" aria-haspopup="true" aria-label="Category filter" className="e7c6503c"
                              //  onClick={() => sethiddenPropertyMT(!hiddenPropertyMT)}
                              >
                                <span className="ef5cccac">
                                  <span className="fontCompensation">{subTypeValue}</span>
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" className="eedc221b">
                                  <path className="cls-1" d="M12 6L6 0 0 6h12z"></path>
                                </svg>
                              </div>
                              <div className="_0a772a68" ref={dropdownRef} onMouseEnter={() => sethiddenPropertyMT(true)} onMouseLeave={() => sethiddenPropertyMT(false)}>
                                {hiddenPropertyMT && (
                                  <div className="_3f35dbb9 _4c9282ef w-4c9282ef" role="listbox" >
                                    <div>
                                      <div>
                                        <ul className='fe6db599' data-name='Category picker'>
                                          <div className="_1cef7b64">
                                            {PropertyMasterTypesList.map((doc, index) => (
                                              <li
                                                className={`_05b994c0  ${doc.id === selectedItemId ? 'e7e540d2' : ''}`}
                                                key={doc.id}
                                                onClick={() => handleTabClick(doc.id)}
                                              >
                                                {doc.desc}
                                              </li>
                                            ))}
                                          </div>
                                        </ul>
                                      </div>
                                      <div>
                                        {filterFunction && filterFunction(selectedItemId, ListID)?.map((doc, index) => (
                                          <div className="_97ab0b50" key={index} aria-label="Property Type">
                                            <li className={`_31a8085a ${doc.propertySubType.id === subTypeID ? 'b7cdc507' : ''}`}
                                              onClick={() => handleSubTypeSelect(doc.propertySubType.desc, doc.propertySubType.id)}
                                            >{doc.propertySubType.desc}</li>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="_16d0f56f">
                                      <button className="_2d04a3c4" aria-label="Reset" onClick={onRest}>{t('reset')}</button>
                                      <button className="_4b391a14" aria-label="Close" onClick={() => sethiddenPropertyMT(!hiddenPropertyMT)}>{t('close')}</button></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="ad62c515 e937c91d" aria-label="Price filter" data-name="Price (PKR)">
                            <div className="_9dc6d35d" onMouseEnter={() => setPricePopUp(true)} onMouseLeave={() => setPricePopUp(false)}>
                              <label className="_5176f8e1" >{t('priceOMR')}</label>
                              <div role="button" aria-haspopup="true" className="e7c6503c"
                              // onClick={() => setPricePopUp(!pricepopup)}
                              >
                                <span className="ef5cccac">
                                  <span className="_5330011d fontCompensation" aria-label="Min value">{minValue}</span>
                                  <span className="_789f0d41 fontCompensation">
                                    {t('to')}</span><span className="_7b974735 fontCompensation" aria-label="Max value">{maxValue || maxPrice}</span>
                                </span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" className="eedc221b">
                                  <path className="cls-1" d="M12 6L6 0 0 6h12z"></path>
                                </svg>
                              </div>
                              <div className="_0a772a68" onMouseEnter={() => setPricePopUp(true)} onMouseLeave={() => setPricePopUp(false)}>
                                {pricepopup && (
                                  <div className="_3f35dbb9 _0fcbe30a w-0fcbe30a" role="listbox">
                                    <div>
                                      <div className='_813e6d60 d-flex'>
                                        <div className='_90cecf69'>
                                          <span className='_5af635f0'>{t('min')}:</span>
                                          <div className='a0c631cb f2679835'>
                                            <input id='activeNumericInput' placeholder='0' spellCheck="false" className="_12173fb7" value={minValue || 0} onChange={handleMinValueChanges} />
                                          </div>
                                        </div>
                                        <div className='_90cecf69'>
                                          <span className='_5af635f0'>{t('max')}:</span>
                                          <div className='a0c631cb f2679835'>
                                            <input id="activeNumericInput" placeholder="any" onChange={handleMaxValueChange} className="_12173fb7" value={maxValue} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="_16d0f56f">
                                      <button className="_2d04a3c4" aria-label="Reset" onClick={(e) => {
                                        setPricePopUp(!pricepopup);
                                        handleFindButtonClick();
                                      }}>{t('apply')}</button>
                                      <button className="_4b391a14" aria-label="Close" onClick={onPriceRest}>{t('clear')}</button></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="ad62c515 c2bc0e05" aria-label="Beds filter" data-name="beds"
                          //  style={{ visibility: selectedItemId === 2 ? "hidden" : "visible" }}
                          style={{ pointerEvents: selectedItemId===2 ? "none" : "auto",
                            backgroundColor: selectedItemId===2 ? "#D3D3D3" : "#007BFF", 
                             cursor: selectedItemId===2 ? "not-allowed" : "pointer",opacity:selectedItemId===2 ?"0.8":"1" }}
                           >
                            <div className="_9dc6d35d"  onMouseEnter={() => setBedPopUp(true)}
                              onMouseLeave={() => setBedPopUp(false)}>
                              <label className="_5176f8e1" >{t('bed')}</label>
                              <div role="button" aria-haspopup="true" className="e7c6503c"
                              //  onClick={() => setBedPopUp(!bedpopup)}
                              >
                                <span className="ef5cccac"><span className="fontCompensation">{TextFieldValue}</span></span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" className="eedc221b">
                                  <path className="cls-1" d="M12 6L6 0 0 6h12z"></path>
                                </svg>
                              </div>
                              <div className="_0a772a68" onMouseEnter={() => setBedPopUp(true)} onMouseLeave={() => setBedPopUp(false)}>
                                {bedpopup && (
                                  <div className="_3f35dbb9" role="listbox">
                                    <div>

                                      <div role="listbox" className="ede17658 customizable-overflow">
                                        {Bedlist.map((value) => (
                                          <span aria-selected="false" className="d92d11c7" key={value}>
                                            <button aria-label="Oman" value={value} className={`bed-bath _933a9a61 _5dd5033c mb-2 ${selectedItems.includes(value) ? 'active lang-btn' : ''}`} onClick={() => handleBedSelect(value)}>  {value}</button>
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="_16d0f56f">
                                      <button className="_4b391a14" aria-label="Close" onClick={() => setBedPopUp(!bedpopup)}>Close</button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box className="modalstyle-phone">
                  <div className='py-2 '>
                    <div className='f16'>
                      {t('purpose')}
                    </div>
                    <div className='py-2 f14'>
                      {propertyList && propertyList.slice(0, 2).map((purposeList, index) => (
                        <button
                          type="submit"
                          key={index}
                          className={`px-4 btn rounded-pill mb-2 f14 ${purposeList.listingPurposeType === ListID ? 'mobilerentbtn ' : 'mobilerentbtn1'
                            }`}
                          onClick={() => switchType(purposeList)}
                        >
                          {purposeList.desc}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Form.Group controlId="formPurpose">
                      <Form.Label>{t('wilayat')}</Form.Label>
                      <Form.Select
                        className='wilayatmobile' value={waliyatId} onChange={handleChangeWilayat}>

                        <option value={null}>{t('Any')}</option>
                        {propertyCityList && propertyCityList.map((doc, index) => (
                          <option key={index} value={doc.id}>{doc.name}</option>
                        ))}
                      </Form.Select >
                    </Form.Group>
                  </div>
                  <div className='py-2'>
                    <Form.Group controlId="formPurpose" >
                      <Form.Label>{t('location')}</Form.Label>
                      <CustomAutocompleteMobile
                        onChange={handleAutocompleteChange}
                        id="free-solo-with-text-demo"
                        value={selectedOption || ''}
                        options={filteredOptions}
                        sx={{ width: 300 }}
                        getOptionLabel={(option: Area) => option.name || ''}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <div>
                                  {params.InputProps.endAdornment}
                                  <StyledInputAdornment position="end">
                                    {/* <IoLocationOutline /> */}
                                  </StyledInputAdornment>
                                </div>
                              ),
                            }}
                          />
                        )}
                        renderOption={(props, option: { id: number; name: string; wilayatId: number }) => (
                          <li {...props} key={option.id} >
                            <span>{option.name}</span>
                          </li>
                        )}
                      />
                    </Form.Group>
                  </div>
                  <hr></hr>
                  <div className='py-2 '>
                    <div className='f16'>
                      {t('propertyTypes')}
                    </div>
                    <div className='py-2 f14'>
                      {PropertyMasterTypesList && PropertyMasterTypesList.map((doc, index) => (
                        <button
                          type="submit"
                          key={index}
                          className={`px-4 btn mb-2 rounded-pill f14  ${doc.id === selectedItemId ? 'mobilerentbtn ' : 'mobilerentbtn1'
                            }`}
                          onClick={() => handleTabClick(doc.id)}
                        >
                          {doc.desc}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className='py-2 '>
                    <div className='f16'>
                      {t('propertySubTypes')}
                    </div>
                    <div className='py-2 f14'>
                      {(selectedItemId === 1 || selectedItemId === 2) && (
                        <button type="submit"
                          className={`px-4 btn  rounded-pill f14 mb-2 ${subTypeID === null ? 'mobilerentbtn' : 'mobilerentbtn1'}`}
                          onClick={() => handleSubTypeSelect('All', null)}
                        >
                          {t('All')}
                        </button>
                      )}
                      {filterFunction && filterFunction(selectedItemId, ListID)?.map((doc, index) => (
                        <button
                          type="submit"
                          key={index}
                          className={`px-4 btn rounded-pill f14 mb-2 ${doc.propertySubType.id === subTypeID ? 'mobilerentbtn ' : 'mobilerentbtn1'
                            }`}
                          onClick={() => handleSubTypeSelect(doc.propertySubType.desc, doc.propertySubType.id)}
                        >
                          {doc.propertySubType.desc}
                        </button>
                      ))}
                    </div>
                  </div>
                  <hr></hr>
                  <div className='py-2 ' style={{display:selectedItemId===2?"none":"block"}}>
                    <div className='f16'>
                      {t('bed')}
                    </div>
                    <div className='py-2' style={{display:selectedItemId===2?"none":"block"}}>
                      <div className="row gx-4 f14 ">
                        <div className='row-container'>
                          {Bedlist.map((value) => (
                            <button type="button" key={value} value={value} className={`bed-bath ${selectedItems.includes(value) ? 'active lang-btn' : ''}`} onClick={() => handleButtonClick(value)}>
                              {value}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{display:selectedItemId===2?"none":"block"}}></hr>
                  <div className='py-2 '>
                    <div className='f16'>
                      {t('priceRange')}
                    </div>
                    <div className='d-flex gap-4 py-2'>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control placeholder="From" value={minValue} onChange={handleMinValueChanges} />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control placeholder="Upto" value={maxValue || maxPrice} onChange={handleMaxValueChange} />
                      </Form.Group>
                    </div>
                    <Slider
                      getAriaLabel={() => 'Minimum distance shift'}
                      value={[minValue, maxValue || 175000]}
                      onChange={handleSliderChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={175000}
                      getAriaValueText={valuetext}
                      disableSwap
                    />
                  </div>
                  <hr></hr>
                  <div className="py-2  d-flex gap-4">
                    <button className='btn-light' onClick={handleClear}>{t('clear')}</button>
                    <button className='btn btn-primary appbtn' onClick={goToPosts}>{t('ApplyFilter')}</button>
                  </div>
                </Box>
              </Modal>
            </div>
          </section>
          <section>
            <BrowseProperty
              Bedlist={Bedlist}
              goToPostsbedroom={goToPostsbedroom}
              goToPostResidential={goToPostResidential}
            />
            <PopularArea setLoading={setLoading} />
          </section>
          <article >
            <SuccessPage />
            <Packages />
            <PopularSearches handleButtonClickpopular={handleButtonClickpopular} listingpurposeid={listingpurposeid} loadingPopular={loadingPopular} />
          </article>
          {/* <Chat /> */}
        </main>
      </div>
    </div>
  )
}

export default HomeFilter
