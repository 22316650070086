import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  propertyTypeID: null, // 1 or 2
  bedRoom: [], // [1,2....,5]
  wilayatID: null, // Wilayat ID (e.g., Muscat)
  areaID: null, // Area ID (e.g., Adam)
  propertySubTypeID: null, // SubType ID (e.g., room or flat)
  propertyMasterTypeID: null, // Residential, Commercial, etc.
  minPrice: null, // Minimum price filter
  maxPrice: null
 
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    // Update a specific filter field
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      if (key in state) {
        state[key] = value;
      } else {
        console.warn(`Invalid key: ${key} not found in filter state`);
      }
    },

    // Reset all filters to their initial state
    resetFilters: () => initialState,

    // Clear a specific filter field
    clearFilter: (state, action) => {
      const { key } = action.payload;
      if (key in state) {
        state[key] = initialState[key];
      } else {
        console.warn(`Invalid key: ${key} not found in filter state`);
      }
    },

    // Append a value to the bedRoom filter (array)
    addBedRoom: (state, action) => {
      const room = action.payload;
      if (!state.bedRoom.includes(room)) {
        state.bedRoom.push(room);
      }
    },

    // Remove a value from the bedRoom filter (array)
    removeBedRoom: (state, action) => {
      const room = action.payload;
      state.bedRoom = state.bedRoom.filter((item) => item !== room);
    },
  },
});

export const {
  setFilter,
  resetFilters,
  clearFilter,
  addBedRoom,
  removeBedRoom,
} = filterSlice.actions;

export default filterSlice.reducer;
